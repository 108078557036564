import List from "@/api/api-list";

export default (context, inject) => {
 // Initialize API factories
 const factories = {
 list: List(context.$axios),

 };

 // Inject $api
 inject("api", factories);
};
