
import lang from '@/mixins/lang.js'

export default {
  name: 'Footer',
  mixins: [lang],
  data() {
    return {
      pageData: {},
      cdnUrl: '',
    }
  },
  async fetch() {
    this.cdnUrl = await process.env.CDN_URL
    await this.browserLang
    const { data } = await this.$axios.get(
      `/site-settings?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.pageData = data.footerSettings
  },
}
