
import icons from './icon.json'

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: 'users',
    },
  },
  computed: {
    path() {
      return icons[this.name]
    },
  },
}
