
import lang from '@/mixins/lang.js'

export default {
  mixins: [lang],
  data() {
    return {
      notification: {},
      show: true,
    }
  },
  async created() {
    await this.browserLang
    const { data } = await this.$axios.get(
      `/site-settings?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : (await this.browserLang) === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.notification = data.notificationBar
  },
  methods: {
    close() {
      this.show = false
    },
  },
}
