export default {
  data() {
    return {
      fullPath: this.$api.list.setLangSlug(this.$route.path),
      path: this.$api.list.getPathWithOutLang(this.$route.path),
      activeLang: this.$api.list.getLangFromPath(this.$route.path),
      browserLang: ''
    }
  },
  beforeMount() {
    this.browserLang = navigator.language
  },
  mounted() {
    setTimeout(() => {
      this.changeLangContent();
    }, 500);
  },
  methods: {
    // TODO: This solution is temporary. Needs to be develop more manageable solution instead of this.
    async changeLangContent() {
      await this.browserLang
      let lang;
      lang = await this.activeLang && this.activeLang.length > 0 ?
        this.activeLang :
        this.browserLang === 'tr-TR' ?
        'tr' :
        'en';

      let elements = document.querySelectorAll('[lang]:not(html)')
      if (!!elements) {
        for (let element of elements) {
          switch (lang) {
            case 'tr':
              if (element.lang === 'en') element.remove()
              this.placeholder = 'Giriniz'
              this.placeholderSelect = 'Seçiniz'
              break;
            case 'en':
              if (element.lang === 'tr') element.remove()
              this.placeholder = 'Please fill out the input'
              this.placeholderSelect = 'Please choose'
              break;
            default:
              if (element.lang === 'tr') element.remove()
              this.placeholder = 'Please fill out the input'
              this.placeholderSelect = 'Please choose'
          }
        }
      }
    }
  }
}
