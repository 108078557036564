
export default {
  name: 'Countdown',
  props: {
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return { remainingTime: '', difference: '', trMessage: '', enMessage: '' }
  },
  async mounted() {
    this.start()
  },
  methods: {
    calculate() {
      let startDate = new Date(this.startDate)
      let today = new Date()
      this.difference = startDate - today
      if (this.difference <= 0) {
        this.remainingTime = ''
        this.trMessage = 'Yarışma Başladı'
        this.enMessage = 'Started'
        clearInterval(this.start)
        if (today > this.endDate) {
          this.trMessage = 'Yarışma Bitti'
          this.enMessage = 'Finished'
        }
      } else {
        let days = Math.floor(this.difference / (1000 * 60 * 60 * 24))
        let hours = Math.floor(
          (this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        hours = ('0' + hours.toString()).slice(-2)
        let minutes = Math.floor(
          (this.difference % (1000 * 60 * 60)) / (1000 * 60)
        )
        minutes = ('0' + minutes.toString()).slice(-2)
        let seconds = Math.floor((this.difference % (1000 * 60)) / 1000)
        seconds = ('0' + seconds.toString()).slice(-2)

        this.remainingTime = `${days} : ${hours} : ${minutes} : ${seconds}`
      }
    },
    start() {
      setInterval(this.calculate, 1000)
    },
  },
}
