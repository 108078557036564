
import lang from '@/mixins/lang.js'
export default {
  name: 'Cookie',
  mixins: [lang],
  async created() {
    await this.browserLang
    const { data } = await this.$axios.get(
      `/site-settings?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.pageData = data.cookieNotification
  },
  data() {
    return {
      pageData: {},
      cookieStorage: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      try {
        // Storage
        this.cookieStorage = localStorage.getItem('ghcookie')

        switch (this.cookieStorage) {
          case null:
            localStorage.setItem('ghcookie', 'notchecked')
            this.cookieStorage = true
            break
          case 'notchecked':
            this.cookieStorage = true
            break
          default:
            break
        }
      } catch (err) {
        return false
      }
    },
    close() {
      localStorage.setItem('ghcookie', 'checked')
      this.cookieStorage = false
    },
  },
}
