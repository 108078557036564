
import lang from '@/mixins/lang.js'

import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin.js'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default {
  name: 'Header',
  mixins: [lang],
  data() {
    return {
      pageData: {},
      menu: {},
      cdnUrl: '',
      setLang: '',
      footerLogo: {},
      navbarToggle: false,
      scrollTop: false,
    }
  },
  async fetch() {
    this.cdnUrl = await process.env.CDN_URL
    await this.browserLang
    const { data } = await this.$axios.get(
      `/site-settings?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : (await this.browserLang) === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.pageData = data.headerSettings
    this.footerLogo = await data.footerSettings.logo

    const navigation = await this.$axios.get(
      `/navigation?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : (await this.browserLang) === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.menu = navigation.data.menu
  },
  mounted() {
    this.setLanguage()
    // Navigation
    let header = this.$refs['header']
    ScrollTrigger.create({
      trigger: 'body',
      start: '30px top',
      end: '+=50000 bottom',
      onEnter: (progress) => {
        header.classList.remove('not-entrance')
        header.classList.add('is-entrance', 'header__fixed')
        let collapse = this.$refs['nav-collapse']
        if (!!collapse) collapse.show = false
        this.scrollTop = true
      },
      onLeaveBack: (progress) => {
        header.classList.add('not-entrance')
        header.classList.remove('is-entrance', 'header__fixed')
        let collapse = this.$refs['nav-collapse']
        if (!!collapse) collapse.show = false
        this.scrollTop = false
        this.navbarToggle = false
      },
    })
  },
  methods: {
    async setLanguage() {
      await this.activeLang
      this.setLang =
        this.activeLang === 'tr'
          ? 'en'
          : this.browserLang === 'tr-TR'
          ? 'en'
          : 'tr'
    },
    toggleNavbar() {
      let header = this.$refs['header']
      // Toggle the value of navbarToggle property
      this.navbarToggle = !this.navbarToggle
      console.log('scrollTop', this.scrollTop)
      if (this.navbarToggle) {
        console.log('navbarToggle', this.navbarToggle)
        header.classList.remove('not-entrance')
        header.classList.add('is-entrance', 'header__fixed')
      } else {
        console.log('navbarToggle', this.navbarToggle)
        header.classList.add('not-entrance')
        header.classList.remove('is-entrance', 'header__fixed')
      }
    },
  },
}
