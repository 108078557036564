
export default {
  name: 'BackToTop',
  data() {
    return {
      scrollNum: 0,
    }
  },
  mounted() {
    window.addEventListener(
      'scroll',
      () => {
        let top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
        this.scrollNum = top
      },
      { passive: true }
    )
  },
  methods: {
    goTop() {
      document.documentElement.scrollTop = 0
    },
  },
}
