
export default axios => ({
  setLangSlug(path){
    let cleanPath = path.split("/");
    cleanPath.shift();
    if(cleanPath[0] == "en"){
      return path.replace('/en', '');
    }else{
      return path
    }
  },

  getLangFromPath(path){
    return path.split('/').slice(1)[0]
  },


  getPathWithOutLang(path){
    return `/${path.split("/").filter(item=>item.length>0).splice(1).join('/')}`;
  }
});