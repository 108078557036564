import Vue from 'vue'

Vue.filter('formatDate', (value) => {
  const date = new Date(value)
  return date.toLocaleString(['tr-TR'], {
    month: 'numeric',
    day: '2-digit',
    year: 'numeric',
  })
})
