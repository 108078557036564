
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Markdown',
  components: { VueMarkdown },
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  methods: {
    changeImgProvider(x) {
      return x.replaceAll('(/uploads/', `(${process.env.CDN_URL}/uploads/`)
    },
  },
}
